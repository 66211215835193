<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="categories"
            select="value"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListCountry
      v-if="selected === 'country'"
      :group="group"
      :templateType="templateType"
      :countryType="countryType">
    </ListCountry>

    <ListProvince
      v-else-if="selected === 'province'"
      :group="group"
      :templateType="templateType"
      :countryType="countryType">
    </ListProvince>

    <ListDistrict
      v-else-if="selected === 'district'"
      :group="group"
      :templateType="templateType"
      :countryType="countryType">
    </ListDistrict>

    <ListSubdistrict
      v-else-if="selected === 'subdistrict'"
      :group="group"
      :templateType="templateType"
      :countryType="countryType">
    </ListSubdistrict>
  </div>
</template>

<script>
import ListCountry from './ListCountry'
import ListProvince from './ListProvince'
import ListDistrict from './ListDistrict'
import ListSubdistrict from './ListSubdistrict'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    countryType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Country${this.$form.capitalize(this.countryType)}List`,
      categories: [
        {text: 'ประเทศ', value: 'country'},
        {text: 'จังหวัด', value: 'province'},
        {text: 'อำเภอ', value: 'district'},
        {text: 'ตำบล', value: 'subdistrict'},
      ],
      selected: 'country',
      rFields: ['selected']
    }
  },
  watch: {
    selected: 'setRetaining'
  },
  components: {
    ListCountry,
    ListProvince,
    ListDistrict,
    ListSubdistrict,
  }
}
</script>

<style lang="css" scoped>
</style>
