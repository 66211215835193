<template>
  <sgv-table
    :rKey="rKey"
    :items="provinces"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td
          v-if="hidden.name"
          @click="toDetail(item.id)"
          class="pointer">
          {{item.name}}
        </td>
      </tr>
    </template>

    <b-button
      slot="action"
      variant="link"
      v-if="$auth.hasRole(`country:${countryType}:add`)"
      class="text-success"
      @click="toDetail(0)">
      เพิ่ม
    </b-button>

  </sgv-table>
</template>

<script>
import { LIST_PROVINCE } from './graph'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    countryType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Country${this.$form.capitalize(this.countryType)}ProvinceDetail`,
      rKey: `Country${this.$form.capitalize(this.countryType)}ListProvince`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name'],
        column: null,
        search: null,
        toolbar: null,
      },
      provinces: [],
    }
  },
  apollo: {
    provinces: {
      query () {
        return LIST_PROVINCE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          countryType: this.countryType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params
      }
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {provinceId: id}
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
